
<template>
  <swiper class="swiper" :options="swiperOption">
    <swiper-slide class="cus_slide slide_1">
      <div class="slide_1_content slide_content">
        <div class="bg_slide_1"><img src="../../assets/groupIntro/page_1_bg_2.png"/></div>
        <div class="cus_flex_box cus_flex_box1">
          <div class="cus_flex_item">
            <div class="s_title animated" id="dom0">集团简介</div>
            <div class="s_num">一</div> <div class="s_line"></div>
            <div class="s_text animated" id="dom0_c">
              琪尔特集团有限公司是一家专门从事设计、运营、销售鞋服、配饰等潮流时尚装备、多品牌的现代化集团公司。
              公司创立于2001年，作为国际知名IP专业运营商，旗下运营品牌包括：巴布豆（BOBDOG）、
              大嘴猴（paul frank）、波波泰迪（popple teddy）等多个国际知名的IP品牌，全面满足消费者的多
              元化需求。
            </div>
          </div>
          <div class="cus_flex_item flex_item_2">
            <!-- <div class="s_title"></div> -->
            <div class="s_title_small animated" id="s_mouse"><span>滚动鼠标继续   <span style="color:#EE660E"> / </span>  <span style="
    font-weight: bolder;
    font-size: 16px;
"> Scroll to Next</span></span><img src="../../assets/groupIntro/mouse_icon.gif"/></div>
            <div class="s_title_line_box">
              <div class="s_num">二</div> <div class="s_line"></div>
              <div class="s_page"><img src="../../assets/groupIntro/page_1.png"/></div>
            </div>
            <div class="s_text animated" id="dom0_t">
              琪尔特集团有限公司坚持“多品牌、高科技、国际化”的发展策略，
              秉承“互相成就、共益发展、持续创新”的企业精神，
              连续成功帮助了多个国际IP品牌创造了在中国鞋服行业的新商业传奇。
              目前集团在全国已拥有了4000多家门店，覆盖全国30多个省、自治区、直辖市。
              连续三年荣膺“中国500最具价值品牌”，其创始人林玉琪先生更为业界誉为“中国童鞋设计教父”。
              展望未来，琪尔特集团致力于帮助更多品牌在中国这片优沃土壤，缔造新的商业传奇，成为一家百年企业。
            </div>
          </div>
        </div>
      <div class="s_bottom_bg" @click="videoVisible=true"><img src="../../assets/groupIntro/page_1_video_bg.png"/></div>
      </div>
      <div v-if="videoVisible" >
      <i class="el-icon-circle-close cus_close_icn" @click="videoVisible=false"></i>
      <video id="myVideo" width="1000" class="group_video" controls="controls" :src="$cndHost + '/storage/media/group_promotional_film.mp4'"></video>
      </div>
    </swiper-slide>
    <swiper-slide class="cus_slide slide_1">
      <div class="slide_1_content slide_content">
        <!-- <div class="bg_slide_1"><img src="../../assets/groupIntro/page_1_bg_2.png"/></div> -->
        <div class="cus_flex_box cus_flex_box2">
          <div class="cus_flex_item">
            <div class="s_title" id="title2">核心价值观</div>
            <div class="s_text s_text_2" id="title2_c">
              以创新科技驱动鞋服产业新未来
            </div>
            <div class="s_num s_num2 s_num2_2 s_num3">三</div> <div class="s_line s_line3"></div>
            
          </div>
          <div class="cus_flex_item flex_item_2">
            <div class="s_title" id="title3">使命愿景</div>
             <div class="s_text s_text_2" id="title3_c">
              致力于引领科技时尚，创造健康、舒适、快乐的新生活方式
              成为中国鞋服行业“多品牌、高科技、国际化”的第一品牌运营商
            </div>
            <!-- <div class="s_title_small"><span>滚动鼠标继续   <span style="color:#EE660E">/</span>   Scroll to Next</span><img src="../../assets/groupIntro/mouse_icon.gif"/></div> -->
            <div class="s_title_line_box s_title_line_box_2">
              <div class="s_num s_num2 s_num4">四</div> <div class="s_line s_line4"></div>
              <div class="s_page s_page2"><img src="../../assets/groupIntro/page_2.png"/></div>
            </div>
           
          </div>
        </div>
        <div class="s_bottom_bg s_bottom_bg_2">
          <img src="../../assets/groupIntro/page_2_bg2.png"/>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="cus_slide slide_1">
      <div class="slide_1_content slide_content">
        <div class="bg_slide_1 bg_slide_3"><img src="../../assets/groupIntro/page_3_bg_2.png"/></div>
        <div class="cus_flex_box" style="margin-top:2%">
          <div class="cus_flex_item" style="width:100%">
            <div class="s_title" id="title4">三大<br/>发展战略</div>
            <div class="s_num s_num5">五</div> <div class="s_line s_line5"></div>
             <div class="s_title_line_box s_title_line_box_3">
              <div class="s_page s_page3"><img  src="../../assets/groupIntro/page_3.png"/></div>
            </div>
          </div>
          <div class="top_box">
              <div class="top_title top_title_1" id="title5">以设计为战略核心</div>
              <div class="top_title_small" id="title5_c">
                  智能化、国际化、时尚化的设计理念
                    专注消费者体验的产品品牌的设计思维
                    协同知名国际设计机构的专业研发设计团队
              </div>
          </div>
          <div class="left_box">
              <img id="img_p3" src="../../assets/groupIntro/page_3_image_3.png"/>
              <div class="top_title" id="title6">以消费者体验为中心</div>
              <div class="top_title_small" id="title6_c" style="text-align:center">
                  整合渠道、供应链资源，<br>
抢占大型shopping mall资源，<br>
逐步构建与完善零售体系。
              </div>
          </div>
          <div class="right_box">
              <img id="img_p1" src="../../assets/groupIntro/page_3_image_1.png"/>
              <div class="top_title" id="title7">以亲子生活为导向</div>
              <div class="top_title_small" id="title7_c">
                  以小童、青少年、成人年龄段全覆盖为架构，多品牌布局，以线上线下全渠道的经营理念，全面捕捉销售机遇

              </div>
          </div>
          <div class="middle_box">
              <img id="img_p2" src="../../assets/groupIntro/page_3_image_2.png"/>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="cus_slide slide_1">
      <div class="slide_1_content slide_content">
        <!-- <div class="bg_slide_1"><img src="../../assets/groupIntro/page_1_bg_2.png"/></div> -->
        <div class="cus_flex_box cus_flex_box4">
          <div class="cus_flex_item">
            <div class="s_title" id="title8">远景战略</div>
            <div class="s_text s_text_2" id="title8_c">
              成为“多品牌、高科技、国际化”的时尚生态型标杆企业
            </div>
            <div class="s_num s_num2 s_num2_2 s_num3">六</div> <div class="s_line s_line3"></div>
            
          </div>
          <div class="cus_flex_item flex_item_2">
            <div class="s_title" id="title9">全球化战略</div>
             <div class="s_text s_text_2" id="title9_c">
              走出国门，拥抱世界。<br/>
琪尔特集团坚定"走出去"战略，以国际化的战略高度引领企业发展方向。
            </div>
            <!-- <div class="s_title_small"><span>滚动鼠标继续   <span style="color:#EE660E">/</span>   Scroll to Next</span><img src="../../assets/groupIntro/mouse_icon.gif"/></div> -->
            <div class="s_title_line_box s_title_line_box_2">
              <div class="s_num s_num2 s_num7">七</div> <div class="s_line s_line4"></div>
              <div class="s_page s_page2"><img src="../../assets/groupIntro/page_4.png"/></div>
            </div>
          </div>
        </div>
        <div class="page_4_bottom_content">
            <div class="c_flex">
                <div class="c_flex_item c_f_1"><img id="img_p5" src="../../assets/groupIntro/page_4_image_1.png"/></div>
                <div class="c_flex_item c_f_2"><img id="img_p6" src="../../assets/groupIntro/page_4_image_2.png"/></div>
                <div class="c_flex_item c_f_3">
                    <div class="c_title_content c_title_content_1">
                    <div class="c_title" id="title10">搭载国际平台<br/>响应“一带一路”倡议</div>
                    <div class="c_title_small" id="title10_c">整合渠道、供应链资源，抢占大型shopping mall资源，逐步构建与完善零售体系</div>
                    </div>
                    <div class="c_title_content">
                    <div class="c_title" id="title11">国际<br/>市场布局</div>
                    <div class="c_title_small" id="title11_c">国际品牌合作，通过代理或并购多个IP品牌，持续在国际市场发力
<p style="padding:0;margin:0;margin-top:-16px">全球知名设计师合作，整合国内外设计团队及全球时尚资源</p></div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- <div class="s_bottom_bg"><img src="../../assets/groupIntro/page_4_bg_2.png"/></div> -->
      </div>
      <img class="p4_bg" src="../../assets/groupIntro/page_4_bg_2.png"/>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
    <!-- <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div> -->
  </swiper>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        videoVisible: false,
        // videoUrl: require('../../assets/group_promotional_film.mp4'),
        swiperOption: {
          direction: 'vertical',
          slidesPerView: 1,
          height: window.innerHeight,
          spaceBetween: 30,
          mousewheel: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          on: {
            slideChangeTransitionStart: function(){
              let dom0 = document.getElementById('dom0')
              let dom0_c = document.getElementById('dom0_c')
              let s_mouse = document.getElementById('s_mouse')
              let dom0_t = document.getElementById('dom0_t')

              let dom2 = document.getElementById('title2');
              let dom2_c = document.getElementById('title2_c');
              let dom3 = document.getElementById('title3');
              let dom3_c = document.getElementById('title3_c');

              let dom4 = document.getElementById('title4');
              let dom5 = document.getElementById('title5');
              let dom5_c = document.getElementById('title5_c');
              let dom6 = document.getElementById('title6');
              let dom6_c = document.getElementById('title6_c');
              let dom7 = document.getElementById('title7');
              let dom7_c = document.getElementById('title7_c');
              let img_p1 = document.getElementById('img_p1');
              let img_p3 = document.getElementById('img_p3');
              let img_p2 = document.getElementById('img_p2');

              let dom8 = document.getElementById('title8');
              let dom8_c = document.getElementById('title8_c');
              let dom9 = document.getElementById('title9');
              let dom9_c = document.getElementById('title9_c');
              let dom10 = document.getElementById('title10');
              let dom10_c = document.getElementById('title10_c');
              let img_p6 = document.getElementById('img_p6');
              let img_p5 = document.getElementById('img_p5');
              let dom11 = document.getElementById('title11');
              let dom11_c = document.getElementById('title11_c');
              if (this.activeIndex === 0) {
                dom0.classList.add('rotateInUpLeft')
                dom0_c.classList.add('fadeInUp')
                s_mouse.classList.add('bounceInRight')
                dom0_t.classList.add('fadeInUp')
                // 1
                dom2.classList.remove('rotateInUpLeft');
                dom3.classList.remove('rotateInUpLeft')
                dom3_c.classList.remove('fadeInUp')
                // 2
                dom4.classList.remove('rotateInUpLeft')
                dom5.classList.remove('fadeInUp')
                dom5_c.classList.remove('fadeInUp')
                dom6.classList.remove('rotateInUpLeft')
                dom6_c.classList.remove('fadeInUp')
                dom7.classList.remove('rotateInUpLeft')
                dom7_c.classList.remove('fadeInUp')
                img_p1.classList.remove('fadeInRightBig')
                img_p3.classList.remove('fadeInLeftBig')
                img_p2.classList.remove('fadeInUp')
                // 3
                dom8.classList.remove('rotateInUpLeft')
                dom8_c.classList.remove('fadeInUp')
                dom9.classList.remove('rotateInUpLeft')
                dom9_c.classList.remove('fadeInUp')
                dom10.classList.remove('rotateInUpLeft')
                dom10_c.classList.remove('fadeInUp')
                img_p6.classList.remove('fadeInUp')
                img_p5.classList.remove('fadeInUp')
                dom11.classList.remove('rotateInUpLeft')
                dom11_c.classList.remove('fadeInUp')
              }else if (this.activeIndex === 1) {
                
                dom2.classList.add('animated')
                dom2.classList.add('rotateInUpLeft')
                
                dom2_c.classList.add('animated')
                dom2_c.classList.add('fadeInUp')
  
                
                dom3.classList.add('animated')
                dom3.classList.add('rotateInUpLeft')
                
                dom3_c.classList.add('animated')
                dom3_c.classList.add('fadeInUp')
                // 0
                dom0.classList.remove('rotateInUpLeft')
                dom0_c.classList.remove('fadeInUp')
                s_mouse.classList.remove('bounceInRight')
                dom0_t.classList.remove('fadeInUp')
                // 2
                dom4.classList.remove('rotateInUpLeft')
                dom5.classList.remove('fadeInUp')
                dom5_c.classList.remove('fadeInUp')
                dom6.classList.remove('rotateInUpLeft')
                dom6_c.classList.remove('fadeInUp')
                dom7.classList.remove('rotateInUpLeft')
                dom7_c.classList.remove('fadeInUp')
                img_p1.classList.remove('fadeInRightBig')
                img_p3.classList.remove('fadeInLeftBig')
                img_p2.classList.remove('fadeInUp')
                // 3
                dom8.classList.remove('rotateInUpLeft')
                dom8_c.classList.remove('fadeInUp')
                dom9.classList.remove('rotateInUpLeft')
                dom9_c.classList.remove('fadeInUp')
                dom10.classList.remove('rotateInUpLeft')
                dom10_c.classList.remove('fadeInUp')
                img_p6.classList.remove('fadeInUp')
                img_p5.classList.remove('fadeInUp')
                dom11.classList.remove('rotateInUpLeft')
                dom11_c.classList.remove('fadeInUp')
              }else if (this.activeIndex === 2) {
                dom4.classList.add('animated')
                dom4.classList.add('rotateInUpLeft')
  
                
                dom5.classList.add('animated')
                dom5.classList.add('rotateInUpLeft')
                
                dom5_c.classList.add('animated')
                dom5_c.classList.add('fadeInUp')

                
                dom6.classList.add('animated')
                dom6.classList.add('rotateInUpLeft')
                
                dom6_c.classList.add('animated')
                dom6_c.classList.add('fadeInUp')

                
                dom7.classList.add('animated')
                dom7.classList.add('rotateInUpLeft')
                
                dom7_c.classList.add('animated')
                dom7_c.classList.add('fadeInUp')

                
                img_p1.classList.add('animated')
                img_p1.classList.add('fadeInRightBig')

                
                img_p3.classList.add('animated')
                img_p3.classList.add('fadeInLeftBig')

                
                img_p2.classList.add('animated')
                img_p2.classList.add('fadeInUp')

                // 0
                dom0.classList.remove('rotateInUpLeft')
                dom0_c.classList.remove('fadeInUp')
                s_mouse.classList.remove('bounceInRight')
                dom0_t.classList.remove('fadeInUp')
                // 1
                dom2.classList.remove('rotateInUpLeft');
                dom3.classList.remove('rotateInUpLeft')
                dom3_c.classList.remove('fadeInUp')
                // 3
                dom8.classList.remove('rotateInUpLeft')
                dom8_c.classList.remove('fadeInUp')
                dom9.classList.remove('rotateInUpLeft')
                dom9_c.classList.remove('fadeInUp')
                dom10.classList.remove('rotateInUpLeft')
                dom10_c.classList.remove('fadeInUp')
                img_p6.classList.remove('fadeInUp')
                img_p5.classList.remove('fadeInUp')
                dom11.classList.remove('rotateInUpLeft')
                dom11_c.classList.remove('fadeInUp')
              }else if (this.activeIndex === 3) {
                
                dom8.classList.add('animated')
                dom8.classList.add('rotateInUpLeft')
                
                dom8_c.classList.add('animated')
                dom8_c.classList.add('fadeInUp')

                
                dom9.classList.add('animated')
                dom9.classList.add('rotateInUpLeft')
                
                dom9_c.classList.add('animated')
                dom9_c.classList.add('fadeInUp')

                
                dom10.classList.add('animated')
                dom10.classList.add('rotateInUpLeft')
                
                dom10_c.classList.add('animated')
                dom10_c.classList.add('fadeInUp')

                
                dom11.classList.add('animated')
                dom11.classList.add('rotateInUpLeft')
                
                dom11_c.classList.add('animated')
                dom11_c.classList.add('fadeInUp')

                
                img_p5.classList.add('animated')
                img_p5.classList.add('fadeInUp')

                
                img_p6.classList.add('animated')
                img_p6.classList.add('fadeInUp')
                // 0
                dom0.classList.remove('rotateInUpLeft')
                dom0_c.classList.remove('fadeInUp')
                s_mouse.classList.remove('bounceInRight')
                dom0_t.classList.remove('fadeInUp')
                // 1
                dom2.classList.remove('rotateInUpLeft');
                dom3.classList.remove('rotateInUpLeft')
                dom3_c.classList.remove('fadeInUp')
                // 2
                dom4.classList.remove('rotateInUpLeft')
                dom5.classList.remove('fadeInUp')
                dom5_c.classList.remove('fadeInUp')
                dom6.classList.remove('rotateInUpLeft')
                dom6_c.classList.remove('fadeInUp')
                dom7.classList.remove('rotateInUpLeft')
                dom7_c.classList.remove('fadeInUp')
                img_p1.classList.remove('fadeInRightBig')
                img_p3.classList.remove('fadeInLeftBig')
                img_p2.classList.remove('fadeInUp')
                
              }
              
            },
          },
        }
      }
    },
    //创建前设置 body样式 隐藏滚动条
    beforeCreate () {
          document.querySelector('body').setAttribute('style', 'overflow:hidden;')
    },
    //销毁前清除
    beforeDestroy () {
          document.querySelector('body').removeAttribute('style')
    },
  }
</script>

<style scoped>

.cus_slide{
   
}
.swiper-pagination{
    position: fixed;
}
div >>> .swiper-pagination-bullet{
    width: 18px;
height: 18px;
border-radius: 50%;
background: #000000;
opacity: 0.1;
}
div >>> .swiper-pagination-bullet-active{
opacity: 1;
background: #EE660E;

}
.bg_slide_1 img{
  width: 50%;
    position: relative;
    left: 50%;
    margin-left: -560px;
}
.bg_slide_3 img{
    top: 130px;
}
.slide_content{
      margin-left: 280px;
       height: 100vh;
       position: relative;
}
.slide_1_content{

}
.bg_slide_1{

}
.cus_flex_box{
    display: flex;
    justify-content: space-between;
}
.cus_flex_box1{
    position: relative;
    top: 5%;
}
.cus_flex_box2{
    position: relative;
    top: 32%;
}
.cus_flex_box4{
    margin-top: 12%;
}
.cus_flex_item{
    width: 42%;
}
.flex_item_2{
  margin-right: 4%;
}
.s_title{
font-size: 46px;
    font-family: AlibabaPuHuiTiB;
    font-weight: bolder;
    color: #000000;
}
.s_title_line_box{
  position: relative;
}
.s_title_line_box_2{
  top: -34px;
}
.s_num{
width: 42px;
    height: 42px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px 0px rgb(0 0 0 / 5%);
    font-size: 23px;
    font-family: AdobeSongStd;
    font-weight: 300;
    line-height: 42px;
    color: #212121;
    text-align: center;
    margin: 71px 0 30px 0;
}
.s_num2{
     

}
.s_num2_2{

}
.s_num5{
  margin-top: 33px;
}
.s_line{
    height: 1px;
    background-color: #E6E6E6;
    position: relative;
    top: -48px;
    left: 60px;
}
.s_text{
font-size: 16px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #8C8C8C;
line-height: 2;
    width: 76%;
    text-align: justify;
}
.s_title_small{
  font-size: 17px;
font-family: AlibabaPuHuiTiL;
font-weight: 400;
color: #000000;
    padding-top: 35px;
}
.s_title_small img{
      vertical-align: middle;
    margin-left: 5px;
}
.s_page{
      position: absolute;
    right: 0;
    top: -18px;
}
.s_bottom_bg{
      position: absolute;
    bottom: 0;
        margin-left: -280px;
        cursor: pointer;
}
.s_bottom_bg img{
  width: 100%;
  vertical-align: bottom;
}
.s_bottom_bg_2 img{

}
.s_page3{
    top: -116px;
}
.top_box{
position: absolute;
    right: 48%;
    top: 0%;
}
.top_title{
    background: #EE660E;
    color: #FFFFFF;
    display: inline-block;
    padding: 0 35px;
    margin: 83px 0 15px 0;
    font-size: 29px;
}
.top_title_1{
    position: relative;
    left: 0;
}
.top_title_small{
font-size: 16px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #231815;
line-height: 2.26;
width: 311px;
}
.left_box{
    width: 350px;
    position: absolute;
    top: 50%;
}
.right_box{
    width: 350px;
    position: absolute;
    right: 12%;
    top: 50%;
}
.middle_box{
       position: absolute;
    left: 45%;
    top: 29%;
    width: 687px;
    margin-left: -343.5px;
}
.middle_box img{
    width: 92%;
}
.page_4_bottom_content{

}
.c_flex{
    display: flex;
    justify-content: space-between;
}
.c_flex_item{

}
.c_flex_item.c_f_1{
    width: 22%;
        margin-top: -16px;
}
.c_flex_item.c_f_2{
    width: 22%;
        margin-top: -16px;
}
.c_flex_item.c_f_3{
    width: 46%;
}
.c_flex_item img{
    width: 100%;
}
.c_title_content{

}
.c_title_content_1{
    margin-top: -20px;
    padding-bottom: 20px;
}
.c_title{
    font-size: 25px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #5A5A5A;
}

.c_title_small{
font-size: 16px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #5A5A5A;
line-height: 2.66;
}
.p4_bg{
        left: 30%;
    position: absolute;
    bottom: 0;
    width: 40%;
}
.s_num4{
  margin-top: 75px;
}
.s_num7{
  margin-top: 75px;
}
.s_text_2{
       position: relative;
       top: 18px;
    }
@media screen and (max-width:1500px) {
    .c_title_small{
        font-size: 14px;
    }
    .s_title{
        font-size: 32px;
    }
    .s_num{
        margin: 32px 0 32px 0;
    }
    .s_line{
        top: -50px;
    }
    .s_text{
            font-size: 16px;
    line-height: 1.6;
    }
    .s_title_small{
        font-size: 15px;
    padding-top: 20px;
    }
    .s_text_2{
        padding: 18px 0;
    }
    .s_num2{
        margin: 7% 0;
    }
    .s_num3{
        position: relative;
        top: -13px;
    }
    .s_num4{
position: relative;
    top: 0;
    }
    .s_line3{
        top: -65px;
    }
    .s_line4{

    }
    .s_page2{
        top: -13px;
    }
    .s_page3{
        top: -86px;
    }
    .middle_box{
        width: 480px;
        margin-left: -240px;
        top: 30%;
    }
    .top_box{
        right: 16%;
    top: 6%;
    }
    .right_box{
        width: 240px;
        right: 9%;
        top: 53%;
    }
    .right_box img{
        width: 100%;
    }
    .left_box{
        width: 240px;
        top: 53%;
    }
    .left_box img{
        width: 100%;
    }
    .top_title{
        margin: 50px 0 22px 0;
    }
    .top_title_small{
        font-size: 14px;
        line-height: 26px;
    }
    .cus_flex_box1{
        top: 7%;
    }
}
.group_video{
  position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}
.cus_close_icn{
  position: absolute;
    left: 76%;
    top: 19%;
    font-size: 30px;
    cursor: pointer;
}
</style>